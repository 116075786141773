.full-screen-container {
    font-family: "Noto Sans";
    color: #7b7b7b;
    font-weight: bold;
    font-style: normal;
    text-align: left;

    .filter {
        .input-text {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #D9D9D9;
            border-radius: 5px !important;
            opacity: 1;
            height: 40px;

            input {
                border: none!important;
            }
        }

        .select {
            background: #ffffff 0% 0% no-repeat padding-box;
            width: 100%;
        }

        .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
            height: 40px;
            padding: 10px 11px;
        }
        .ant-select:not(.ant-select-customize-input) .ant-select-selector {
            border: 1px solid #D9D9D9;
            border-radius: 5px;
        }

        .ant-select-selector:focus-within {
            border: 1px solid #D9D9D9 !important;
        }

        .ant-select-selector:hover {
            border: 1px solid #7b7b7b !important;
        }

        .ant-select-selector {
            display: flex;
            align-items: center;
        }

        .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
            font-family: "Noto Sans";
            font-size: 14px;
            font-weight: bold;
            line-height: 45.5px;
            font-style: normal;
            text-align: left;
            color: #7b7b7b;
        }

        .ant-image-img {
            width: 14px;
        }
    }

    .working {
        .leftSection {
            background-color: white;
            border: 1px solid;
            border-color: #cbd5e1;
            padding: 8px 20px;
            height: 190px;

            .processNameLevel {
                display: flex;
                align-items: flex-start;

                .inner_wrapper {
                    display: flex;
                    align-items: center;
                    height: 18px;
                }

                .process_Text {
                    font-family: "Noto Sans";
                    letter-spacing: 1px;
                    font-size: 10px;
                    font-weight: 700;
                    font-style: normal;
                    color: #7b7b7b;
                    padding-left: 5px;
                    min-width: 80px;
                }

                .process_Name {
                    font-family: "Roboto";
                    font-size: 5px;
                    font-weight: 700;
                    color: #7b7b7b;
                    width: 60px;
                }

                .process_Level {
                    display: flex;
                    align-items: flex-start;

                    .label {
                        border-radius: 2px;
                        color: #ffffff;
                        background-color: #35AD63;
                        font-family: "Noto Sans";
                        font-style: normal;
                        font-size: 4px;
                        padding: 2px 2px;
                        margin-right: 3px;
                        text-align: center;
                        width: 30px;
                    }

                    .lvlValue {
                        margin: 0px;
                        font-weight: 700;
                        font-size: 6px;
                        font-style: normal;
                    }
                }
            }

            .boostingImage {
                display: flex;
                align-items: center;
                height: 100%;

                .boostingWrapper {
                    width: 15px;
                    padding-bottom: 40px;

                    .vertical-progress {
                        transform: rotate(270deg);
                        display: flex;
                        justify-content: center;
                    
                            .ant-progress-steps-item {
                                background-color: #c7c7c7;
                            }
                            .ant-progress-steps-outer {
                                align-items: none;
                                gap: 1px !important;
                            }
                        }
                }

                .avatarImageContainer,
                .newAvatarImageContainer {
                    .imgContainer {
                        height: 140px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }

        .rightSection {
            display: flex;
            flex-direction: column;
            align-items: flex-end;

            background-color: white;
            border: 1px solid;
            border-color: #cbd5e1;
            padding: 8px 0px;
            height: 190px;

            .upperData {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                margin-right: 20px;

                .total-number-work {
                    text-align: center;
                    background: rgb(9, 9, 121);
                    background: linear-gradient(
                        90deg,
                        rgba(107, 184, 236, 1) 0%,
                        rgba(0, 106, 178, 1) 21%,
                        rgba(0, 106, 178, 1) 100%
                    );
                    height: 25px;
                    width: auto;
                    min-width: 180px;
                    line-height: 25px;
                    color: white;
                    position: relative;
                    margin-top: 7px;

                    .totalNumberWork__innerWrapper {
                        display: flex;
                        justify-content: space-between;
                        padding-left: 10px;
                        padding-right: 10px;
                        align-items: center;
                        height: 100%;

                        .legend {
                            display: flex;
                            align-items: center;

                            p {
                                padding-left: 5px;
                                font-family: "Noto Sans";
                                font-size: 8px;
                                font-weight: 700;
                                font-style: normal;
                                color: #ffffff;
                                margin: 0;
                            }
                        }

                        .data {
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 700;
                            font-style: normal;
                            color: #ffffff;
                            margin: 0;
                            margin-left: 0.75rem;
                        }
                    }

                    &:before {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-top: 25px solid #6bb8ec;
                        border-left: 25px solid transparent;
                        position: absolute;
                        right: 100%;
                        top: 0px;
                    }

                    &.total-number-work-sub {
                        min-width: 140px;
                        background: linear-gradient(
                            90deg,
                            rgba(161, 208, 238, 1) 0%,
                            rgba(69, 150, 204, 1) 100%,
                            rgba(0, 106, 178, 1) 100%
                        );
                        &:before {
                            border-top: 25px solid #9bd0ee;
                        }
                    }
                }

                .total-number-time {
                    text-align: center;
                    background: linear-gradient(
                        90deg,
                        rgba(240, 206, 159, 1) 0%,
                        rgba(237, 117, 28, 1) 21%,
                        rgba(237, 117, 28, 1) 100%
                    );
                    height: 25px;
                    width: auto;
                    min-width: 180px;
                    line-height: 25px;
                    color: white;
                    position: relative;
                    margin-top: 7px;

                    .totalNumberTime__innerWrapper {
                        display: flex;
                        justify-content: space-between;
                        padding-left: 10px;
                        padding-right: 10px;
                        align-items: center;
                        height: 100%;
    
                        .legend {
                            display: flex;
                            align-items: center;

                            p {
                                padding-left: 5px;
                                font-family: "Noto Sans";
                                font-size: 8px;
                                font-weight: 700;
                                font-style: normal;
                                color: #ffffff;
                                margin: 0;
                            }
                        }

                        .data {
                            font-family: Roboto;
                            font-size: 14px;
                            font-weight: 700;
                            font-style: normal;
                            color: #ffffff;
                            margin: 0;
                            margin-left: 0.75rem;
                        }
                    }

                    &:before {
                        content: "";
                        width: 0px;
                        height: 0px;
                        border-top: 25px solid #f0cc9c;
                        border-left: 25px solid transparent;
                        position: absolute;
                        right: 100%;
                        top: 0px;
                    }

                    &.total-number-time-sub {
                        min-width: 140px;
                        background: linear-gradient(
                            90deg,
                            rgba(255, 209, 155, 1) 0%,
                            rgba(255, 184, 130, 1) 100%,
                            rgba(255, 252, 250, 1) 100%
                        );

                        &:before {
                            border-top: 25px solid #ffd09a;
                        }
                    }
                }
            }

            .cycleTimeChartWrapper {
                margin-inline-end: 20px;
                width: 160px;
            }
        }

        .userName {
            color: #ffffff;
            background: #1AA9C8;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            padding: 3px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .noOperational {
        min-height: 210px;

        .title {
            color: 37474F;
            background: #ECEFF1;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: calc(100% - 30px);
            display: flex;
            align-items: center;
            justify-content: center;

        }
        .userName {
            color: #ffffff;
            background: #BDBDBD;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .ant-pagination-item {
        cursor: pointer;
        height: 36px;
        width: 36px;
        border: none;

        a {
            height: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #616161;
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #D9D9D9;
            border-radius: 2px;
        }

        a:hover {
            color: white;
            background: #1AA9C8;
            border: #1AA9C8;
        }
    }

    .ant-pagination-item-active {
        a {
            color: white;
            background: #1AA9C8;
            border: #1AA9C8;
        }
    }

    .last-updated-at {
        color: rgba(0, 0, 0, 0.88);
    }

    .ant-pagination-options {
        display: none;
    }
}

