.levelProgressBarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  .level {
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 700;
    font-style: normal;
    color: #ffffff;
    margin-right: 20px;
    margin-left: 10px;

    span {
      font-size: 18px;
    }
  }

  .progressWrapper {
    @media screen and (min-width: 1281px) {
      width: 50%;
    }
    .progressBar {
      @media screen and (max-width: 1281px) {
        width: 600px;
      }
      // @media screen and (max-width: 1130px) {
      //   width: 500px;
      // }
      @media screen and (max-width: 965px) {
        width: 400px;
      }
      @media screen and (max-width: 560px) {
        width: 280px;
      }
    }
  }
}