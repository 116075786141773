.skill-check-container {
  font-style: normal;
  display: flex;
  padding-bottom: 46px;
  max-width: 1280px;
  flex-wrap: wrap;
  
  @media screen and (max-width: 720px) {
    display: block;
    flex-wrap: nowrap;
  }
  ::-webkit-scrollbar {
    width: 15px;
    height: 200px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #7b7b7b;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #7b7b7b;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #7b7b7b;
  }

  .accountSection {
    flex: 1 1 0%;
    min-width: 469px;
    display: flex;
    flex-direction: column;
    padding-top: 94px;
    @media screen and (max-width: 1200px) {
      padding-top: 0px;
    }
    @media screen and (max-width: 720px) {
      margin-bottom: 20px;
    }

    .accountInfo {
      display: flex;
      align-items: center;
      @media screen and (max-width: 820px) {
        justify-content: center;
      }

      .label {
        display: flex;
        flex-direction: column;

        .btn-skill-check {
          border-radius: 5px;
          background: #7b7b7b;
          color: #ffffff;
          font-family: "Noto Sans";
          font-weight: 700;
          font-style: normal;
          font-size: 12px;
          padding: 6px 14px;
          text-align: center;

          &:nth-child(2) {
            margin-top: 10px;
          }
        }
      }

      .data {
        padding-left: 10px;
        display: grid;
        height: 100%;

        .text-name {
          color: #7b7b7b;
          font-size: 18px;
          letter-spacing: 3px;
          margin: 0px;

          &:nth-child(1) {
            align-self: flex-start;
          }

          &:nth-child(2) {
            margin-top: 15px;
            align-self: flex-end;
          }
        }
      }
    }

    .avatarImageContainer {
      margin-top: 81px;
      @media screen and (max-width: 1200px) {
        margin-top: 20px;
        height: 400px;
      }
      .imgContainer {
        @media screen and (max-width: 820px) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .historySection,
  .processSection {
    flex: 1 1 0%;
    padding-top: 53px;

    @media screen and (max-width: 1200px) {
      padding-top: 0px;
    }

    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 720px) {
      margin-bottom: 20px;
    }

    .title {
      font-family: "Noto Sans";
      font-weight: 700;
      color: #7b7b7b;
      font-size: 18px;
      margin-bottom: 16px;
    }

    .operation-history-scroll {
      height: 541px;
      width: 100%;
      max-width: 400px;
      overflow-y: scroll;
      border-radius: 20px;
      float: left;
    }
  }

  .historySection {
    min-width: 335px;
    margin-right: 39px;
    @media screen and (max-width: 1230px) {
      margin-right: 15px;
    }

    .operation-history-container {
      border-radius: 20px;
      filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
      background: #ffffff;
      box-shadow: 0px 3px 6px #00000029;
      opacity: 1;
      font-family: "Noto Sans";
      font-weight: normal;
      color: #1a1311;
      margin-right: 20px;
      min-height: 541px;

      .tableContainer {
        padding-top: 30px;
        padding-left: 35px;
        padding-right: 35px;
        max-width: 370px;

        .typeColumn,
        .dateColumn {
          font-size: 16px;
          font-weight: 700;
          color: #7b7b7b;
          padding: 0px !important;

          p {
            font-family: "Noto Sans";
          }
        }

        .bodyRow {
          padding-top: 16px;

          .typeDataColumn,
          .dateDataColumn {
            padding: 0px !important;

            p {
              font-weight: 400;
              color: #1a1311;
              font-size: 12px;
            }
          }
          
          .typeDataColumn {
            p {
              font-family: "Noto Sans";
            }
          }
        }
      }
    }
  }

  .processSection {
    min-width: 314px;
    margin-right: 70px;

    @media screen and (max-width: 1230px) {
      margin-right: 15px;
    }

    .process-level-container {
      background: #ffffff 0% 0% no-repeat padding-box;
      margin-right: 24px;
      min-height: 541px;
      max-width: 400px;
      
      .skill-check-content {
        border-radius: 20px;
        filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
        background: #ffffff;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-top: none;
        margin-bottom: 20px;
        padding-left: 17px;
        padding-right: 17px;

        .nameLevel {
          display: flex;
          justify-content: space-between;
          padding-top: 36px;

          .text {
            font-size: 16px;
            color: #7b7b7b;
            font-weight: 700;
            font-style: normal;

            // &:nth-child(1) {
            //   font-family: Roboto;
            // }

            &:nth-child(2) {
              font-family: Roboto;
            }
          }
        }

        .progressBar {
          height: 20px;
          padding-top: 9px;
        }

        .cumulativeTimeWrapper {
          display: flex;
          justify-content: space-between;
          padding-top: 18px;

          .total,
          .average {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 1 1 0%;

            p {
              font-weight: 700;

              &:nth-child(1) {
                font-family: "Noto Sans";
                font-style: normal;
                color: #7b7b7b;
                font-size: 12px;
                text-align: center;
              }

              &:nth-child(2) {
                font-family: Roboto;
                font-size: 32px;
                color: #006ab2;
              }
            }
          }

          .average {
            padding-bottom: 10px;
          }
        }

        .vertical-line {
          width: 1px;
          height: 60px;
          background-color: black;
          transform: rotate(180deg);
          color: #7b7b7b;
        }
      }
    }
  }

  .ant-progress-bg {
    height: 20px !important;
  }
}
.skill-check-footer {
  background: #7b7b7b;
  background: linear-gradient(180deg, #7b7b7b 0%, #3e3e3e 100%);
  display: flex;
  justify-content: center;
  padding-top: 27px;
  padding-bottom: 27px;

  .currentPage {
    color: #ffffff;
    font-family: "Noto Sans";
    font-weight: 700;
    font-size: 18px;
    padding-left: 100px;
    padding-right: 100px;
  }
}
