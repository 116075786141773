.notiEffectContainer {
  top: 30%;
  position: absolute;
  width: 100%;
  min-height: 250px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 10px;
  background: rgba(26, 26, 17, 0);
  background: linear-gradient(
    90deg,
    rgba(26, 26, 17, 0) 0%,
    #343434 49.75%,
    rgba(52, 52, 52, 0) 100%
  );
  display: flex;
  justify-content: center;
  align-items: center;

  .btn-item-name {
    border-radius: 16px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #ffffff;
    margin-top: 15px;
    padding-top: 2px;
    padding-bottom: 2px;
    margin: 0px;
    font-family: "Noto Sans";
    display: flex;
    justify-content: center;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 16px;
    font-weight: 700;
    color: #006ab2;
    font-style: normal;
  }
  .newItemWrapper {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .iGotIt {
    margin-top: 7px;
    margin: 0;
    display: flex;
    justify-content: center;
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: 700;
    color: #ffffff;
    font-style: normal;
    padding-top: 5px;
  }

  .levelTagContainer {
    display: flex;
    justify-content: center;
  }

  .levelUpIconWrapper {
    margin: 15px auto 15px;
    display: flex;
    justify-content: center;
  }
  .levelUp {
    font-family: "Noto Sans";
    font-size: 34px;
    font-weight: 500;
    color: #ffffff;
    font-style: normal;
  }
}