.changePasswordModalContainer {
  min-width: 700px;
  z-index: auto;
  .ant-modal-content {
    min-width: 700px;
    max-height: 400px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }
  .ant-modal {
    display: flex;
    position: unset;
    justify-content: center;
    align-items: center;
  }
  .ant-modal-root .ant-modal-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-form-item .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
  }
  .button-change-pw {
    border-radius: 10px;

    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));

    background: #35d6fc;
    background: linear-gradient(
      180deg,
      #35d6fc 0%,
      #27b6d8 91.63%,
      #1d93af 100%
    );
    width: 280px;
    height: 50px;
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    text-align: center;
    color: #ffffff;
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border: none;
  }
}