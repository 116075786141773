.manufacturing-start-container {
  font-family: "Noto Sans";
  color: #7b7b7b;
  font-weight: bold;
  font-style: normal;
  text-align: left;
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
  }

  .text-manufacture {
    font-size: 18px;
    display: flex;
    justify-content: center;
  }
  .title-manufacture {
    font-size: 38px;
    display: flex;
    justify-content: center;
  }
  .button-cycle-time {
    color: #fff;
    border-radius: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #35d6fc;
    background: linear-gradient(
      180deg,
      #35d6fc 0%,
      #27b6d8 91.63%,
      #1d93af 100%
    );
    min-width: 280px !important;
    height: 60px;
  }
  .bt-process-reset {
    background: linear-gradient(
      180deg,
      #aca5a5 0%,
      #7b7b7b 91.63%,
      #5d5858 100%
    );
  }
  .button-cycle-time :focus-within {
    border: none;
    color: #fff;
  }
  .button-cycle-time :hover {
    border: none;
    color: #fff;
  }
  .ant-btn:hover {
    color: #fff;
    border: none;
  }
  .ant-btn-default {
    color: #fff;
    border: none;
  }
}
.ant-modal {
  display: flex !important;
  position: unset !important;
  justify-content: center !important;
  align-items: center !important;
  padding-bottom: 0;
}
.ant-modal-root .ant-modal-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-process-reset {
  .ant-modal-content {
    min-width: 500px;
    min-height: 300px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }

  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border: none;
  }
  .title-process-reset {
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #7b7b7b;
  }
  .button-cycle-time {
    color: #fff;
    border-radius: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #35d6fc;
    background: linear-gradient(
      180deg,
      #35d6fc 0%,
      #27b6d8 91.63%,
      #1d93af 100%
    );
    min-width: 280px !important;
    height: 50px;
  }
}
.modal-process-complete {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border: none;
  }
  .ant-modal-content {
    min-width: 500px;
    min-height: 300px;
    border-radius: 20px;
    box-shadow: 0px 3px 6px #00000029;
  }
  // .ant-modal {
  //   display: flex;
  //   position: unset;
  //   justify-content: center;
  //   align-items: center;
  // }
  // .ant-modal-root .ant-modal-wrap {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  .title-process-complete {
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #7b7b7b;
  }
  .button-cycle-time {
    color: #fff;
    border-radius: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #aca5a5;
    background: linear-gradient(
      180deg,
      #aca5a5 0%,
      #7b7b7b 91.63%,
      #5d5858 100%
    );
    min-width: 280px !important;
    height: 50px;
  }
}
.modal-login {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border: none;
  }
  .ant-modal-content {
    width: max-content;
    min-width: 700px;
    border-radius: 20px;
  }
  .title-login {
    letter-spacing: 0px;
    color: #7b7b7b;
    font-family: "Noto Sans";
    font-weight: bold;
    font-size: 14px;
    display: flex;
    font-style: normal;
    justify-content: center;
    padding-bottom: 5px;
  }
  .input-login {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
    border-radius: 10px;
    opacity: 1;
    height: 60px;
    min-width: 590px;
  }

  .bt-login {
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #ffffff;
    min-width: 280px;
    height: 50px;
    font-weight: bold;
    background: transparent
      linear-gradient(180deg, #35d6fc 0%, #27b6d8 92%, #1d93af 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
  }
}
