.unreadItemContent {
  background-color: #eeeeee;
  padding: 6px 6px;

  .amount {
    margin: 0px 3px;
    color: #E0212C;
    font-weight: 700;
    letter-spacing: 0px;
  }
}