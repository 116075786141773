.CycleTimeChart-container {
  // border: 1px solid green;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  .wholeProgressBar {
    position: relative;
    background-color: #eeeeee;
    width: 100%;
    .currentMileStone {
      width: 1px;
      position: absolute;
      border: 2px solid #EC1E79;
      top: 50%;
      transform: translate(-50%, -50%);
      // .label,
      .value {
        color: #EC1E79;
        text-align: center;
      }
      // .label {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 50px;
      //   transform: translate(-50%, -110%);
      // }
      .value {
        position: absolute;
        left: 0;
        top: 0;
        font-weight: 600;
        width: 50px;
        transform: translate(-50%, -115%);
      }
    }

    .innerHighlighter {
      height: 100%;
      background-color: #ddf1fe;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .minMileStone {
      width: 1px;
      border-left: 1px solid #7b7b7b;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      .value {
        text-align: center;
        width: 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #7b7b7b;
        transform: translate(-50%, 100%);
      }
    }

    .lowerBoundMileStone {
      width: 1px;
      border-left: 1px solid #7b7b7b;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .value {
        text-align: center;
        width: 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #7b7b7b;
        transform: translate(-50%, 100%);
      }
    }

    .medianMileStone {
      width: 1px;
      position: absolute;
      border: 2px solid #006ab2;
      top: 50%;
      // left: 50%;
      transform: translate(-50%, -50%);
      // .label,
      .value {
        color: #006ab2;
        text-align: center;
      }
      // .label {
      //   position: absolute;
      //   left: 0;
      //   top: 0;
      //   width: 50px;
      //   transform: translate(-50%, -110%);
      // }
      .value {
        position: absolute;
        left: 0;
        bottom: 0;
        font-weight: 600;
        width: 50px;
        transform: translate(-50%, 185%);
      }
    }

    .upperBoundMileStone {
      width: 1px;
      border-left: 1px solid #7b7b7b;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      .value {
        text-align: center;
        width: 50px;
        position: absolute;
        left: 0;
        bottom: 0;
        color: #7b7b7b;
        transform: translate(-50%, 100%);
      }
    }

    .maxMileStone {
      width: 1px;
      border-right: 1px solid #7b7b7b;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      .value {
        text-align: center;
        width: 50px;
        position: absolute;
        color: #7b7b7b;
        left: 0;
        bottom: 0;
        transform: translate(-50%, 100%);
      }
    }
  }

  .legends {
    position: absolute;
    display: flex;
    
    .standardCT,
    .currentCT {
      position: relative;

      p {
        position: absolute;
        bottom: -7px;
        right: -35px;
        font-weight: 600;
      }
    }

    .standardCT {
      color: #006ab2;
      border: 2px solid #006ab2;
    }

    .currentCT {
      color: #EC1E79;
      border: 2px solid #EC1E79;
    }
  }
}