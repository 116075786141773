.login-form {
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border: none;
  }
  .title-login {
    letter-spacing: 0px;
    color: #7b7b7b;
    opacity: 1;
    font-family: "Noto Sans";
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .input-login {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #7b7b7b;
    border-radius: 10px;
    opacity: 1;
    height: 60px;
    min-width: 590px;
  }
  .button-login {
    background: transparent
      linear-gradient(180deg, #ff9c53 0%, #ed751c 92%, #b45209 100%) 0% 0%
      no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    color: #ffffff;
    width: 100%;
    height: 60px;
    font-weight: bold;
    font-size: 18px;
  }
  .bt-settings {
    background: transparent
      linear-gradient(180deg, #27a1f4 0%, #006ab2 92%, #07304b 100%) 0% 0%
      no-repeat padding-box;
  }
}
