.newAvatarImageContainer {
  position: relative;
  width: 100%;
  height: 100%;
  .imgContainer {
    position: absolute;
    width: 100%;
    top: 0;
    height: 100%;
    z-index: 0;

    img {
      height: 100%;
    }

    .characterBody {
      position: absolute;
      top: 0;
    }

    .hat {
      position: absolute;
      top: 0;
    }
    
    .cloth {
      position: absolute;
      top: 0;
    }
    
    .shoe {
      position: absolute;
      top: 0;
    }

    .spinContainer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;

      .ant-spin-dot {
        font-size: 65px;

        .ant-spin-dot-item {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}