.fullScreenLayoutContainer {
  .header-full-screen {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    letter-spacing: 1.6px;
    color: #7b7b7b;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 50;
    @media screen and (max-width: 1200px) {
      position: static;
      z-index: auto;
    }
    .header-logo {
      padding: 24px 70px;
      font-family: "Noto Sans";
      font-weight: 700;
      font-style: normal;
      font-size: 16px;
      color: #7b7b7b;
      margin: 0px;
      @media screen and (max-width: 1200px) {
        padding: 20px 30px;
      }
    }
    .returnBtn {
      cursor: pointer;
      margin-right: 70px;
      padding-left: 23px;
      padding-right: 23px;
      padding-top: 5px;
      padding-bottom: 5px;
      max-height: 36px;
      border-radius: 3px;
      border: 2px solid #7b7b7b;
      font-family: "Noto Sans";
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      color: #ffffff;
      background-color: #7b7b7b;
      @media screen and (max-width: 1200px) {
        margin-right: 30px;
      }
    }
    .content-full-screen {
      min-height: 640px;
    }
  }

  .childrenWrapper {
    margin-block-start: 80px;
    margin-inline: 80px;
  }
}
