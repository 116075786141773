.adminWorkContainer {
  position: relative;

  .header-admin {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    letter-spacing: 1.6px;
    color: #7b7b7b;
    .header-logo {
      padding: 24px 70px;
    }
    .content-admin {
      min-height: 640px;
    }
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 50;
    @media screen and (max-width: 1040px) {
      height: 110px;
    }

    @media screen and (max-width: 965px) {
      position: static;
      z-index: auto;
    }
    .accountInfo {
      display: flex;
      padding-left: 100px;
      @media screen and (max-width: 1130px) {
        padding-left: 60px;
        justify-content: space-around;
      }
      @media screen and (max-width: 1040px) {
        flex-direction: column;
      }
      @media screen and (max-width: 965px) {
        padding-left: 20px;
      }
      .accountName,
      .accountId {
        display: flex;
        align-items: center;
        .label {
          border-radius: 5px;
          background: #7b7b7b;
          color: #ffffff;
          font-family: "Noto Sans";
          font-style: normal;
          font-size: 12px;
          padding: 2px 5px;
          text-align: center;
          margin-right: 3px;
        }
        .value {
          color: #7b7b7b;
          margin: 0px;
          font-weight: 700;
          font-size: 16px;
          font-style: normal;
          max-width: 160px;
        }
      }

      .accountName {
        margin-right: 30px;
        @media screen and (max-width: 965px) {
          margin-right: 20px;
        }
        .value {
          min-width: 60px;
        }
      }

      .accountId {
        margin-right: 20px;
      }
    }
    .rightSideWrapper {
      display: flex;
      align-items: center;

      @media screen and (max-width: 965px) {
        flex-direction: column;
        justify-content: space-evenly;
      }
    }
    .changePassword {
      display: flex;
      align-items: center;
      padding-right: 25px;
      padding-left: 30px;
      cursor: pointer;
      @media screen and (max-width: 965px) {
        padding-right: 25px;
      }
      p {
        margin: 0;
        color: #7b7b7b;
        font-weight: 700;
        font-size: 12px;
        font-family: "Noto Sans";
        font-style: normal;
        padding-left: 5px;
      }
    }
  }

  .childrenWrapper {
    display: flex;
    justify-content: center;
    margin-top: 70px;
    width: 100%;
    @media screen and (max-width: 1040px) {
      margin-top: 120px;
    }
    @media screen and (max-width: 965px) {
      margin-top: 30px;
    }

    .work-container {
      display: flex;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 3.5rem;
      @media screen and (max-width: 965px) {
        display: block;
        margin-bottom: 20px;
      }

      .leftSection {
        flex: 1 1 0%;
        min-width: 740px;
        padding-left: 70px;
        @media screen and (max-width: 1280px) {
          min-width: 500px;
          padding-left: 35px;
        }
        @media screen and (max-width: 965px) {
          padding-left: 20px;
          padding-right: 20px;
        }

        .processNameLevel {
          display: flex;
          align-items: flex-start;

          @media screen and (max-width: 560px) {
            display: block;
          }
          
          .inner_wrapper {
            display: flex;
            align-items: center;

            &:nth-child(1) {
              @media screen and (max-width: 560px) {
                margin-bottom: 10px;
              }
            }
          }
          
          .process_Text {
            font-family: "Noto Sans";
            letter-spacing: 8px;
            font-size: 38px;
            font-weight: 700;
            font-style: normal;
            color: #7b7b7b;
            padding-left: 20px;

            @media screen and (max-width: 1280px) {
              font-size: 30px;
              padding-left: 15px;
            }

            @media screen and (max-width: 1050px) {
              font-size: 22px;
            }

            @media screen and (max-width: 965px) {
              font-size: 18px;
              padding-left: 5px;
            }
          }

          .process_Name {
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 500;
            color: #7b7b7b;
            padding-left: 11px;
            margin-right: 20px;

            @media screen and (max-width: 560px) {
              padding-left: 0px;
            }
          }

          .process_Level {
            background-color: #7b7b7b;
            border-radius: 13px;
            font-family: "Roboto";
            font-size: 18px;
            font-weight: 500;
            margin: 0px;
            color: #ffffff;
            padding-left: 13px;
            padding-right: 13px;
            height: 25px;
          }
        }

        .boostingImage {
          display: flex;
          align-items: center;
          height: 100%;
          @media screen and (max-width: 965px) {
            justify-content: center;
          }
          
          .boostingWrapper {
            max-width: 40px;
            margin-right: 70px;
            margin-left: -3px;
            @media screen and (max-width: 1280px) {
              margin-right: 45px;
            }
            @media screen and (max-width: 965px) {
              margin-right: 0px;
              margin-left: 0px;
            }
            
            .vertical-progress {
              transform: rotate(270deg);
              display: flex;
              justify-content: center;
              padding-left: 30px;
              gap: 3px;
              @media screen and (max-width: 965px) {
                padding-left: 0px;
              }
            
              .ant-progress-steps-item {
                background-color: #c7c7c7;
              }
              .ant-progress-steps-outer {
                align-items: none;
              }
            }
          }

          .avatarImageContainer,
          .newAvatarImageContainer {
            max-width: 498px;
            min-height: 300px;
            @media screen and (max-width: 1280px) {
              max-width: 400px;
              height: 400px;
            }

            @media screen and (max-width: 560px) {
              max-width: 320px;
              height: 320px;
            }
            
            .imgContainer {
              display: flex;
              justify-content: center;
            }
          }
        }
      }

      .rightSection {
        flex: 1 1 0%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        @media screen and (max-width: 965px) {
          align-items: center;
          margin: 0 30px;
        }
        .upperData {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          margin-right: 20px;
          @media screen and (max-width: 965px) {
            display: block;
            margin-right: 0px;
            width: 100%;
          }
          
          .total-number-work {
            text-align: center;
            background: rgb(9, 9, 121);
            background: linear-gradient(
              90deg,
              rgba(107, 184, 236, 1) 0%,
              rgba(0, 106, 178, 1) 21%,
              rgba(0, 106, 178, 1) 100%
            );
            min-width: 450px;
            width: auto;
            height: 70px;
            line-height: 60px;
            color: white;
            position: relative;
            margin-top: 20px;
            @media screen and (max-width: 1130px) {
              min-width: 350px;
            }

            .totalNumberWork__innerWrapper {
              display: flex;
              justify-content: space-between;
              padding-left: 25px;
              padding-right: 25px;
              align-items: center;
              height: 100%;

              @media screen and (max-width: 1130px) {
                padding-left: 0px;
                padding-right: 15px;
              }

              @media screen and (max-width: 965px) {
                padding-left: 15px;
              }

              .legend {
                display: flex;
                align-items: center;

                p {
                  padding-left: 5px;
                  font-family: "Noto Sans";
                  font-size: 18px;
                  font-weight: 700;
                  font-style: normal;
                  color: #ffffff;
                  margin: 0;
                }
              }

              .data {
                font-family: Roboto;
                font-size: 44px;
                font-weight: 700;
                font-style: normal;
                color: #ffffff;
                margin: 0;
                margin-left: 0.75rem;
                @media screen and (max-width: 1130px) {
                  font-size: 32px;
                }
              }
            }

            &:before {
              content: "";
              width: 0px;
              height: 0px;
              border-top: 70px solid #6bb8ec;
              border-left: 70px solid transparent;
              position: absolute;
              right: 100%;
              top: 0px;

              @media screen and (max-width: 965px) {
                border: none;
              }
            }

            &.total-number-work-sub {
              min-width: 350px;
              width: auto;
              background: linear-gradient(
                90deg,
                rgba(161, 208, 238, 1) 0%,
                rgba(69, 150, 204, 1) 100%,
                rgba(0, 106, 178, 1) 100%
              );
              &:before {
                border-top: 70px solid #9bd0ee;
              }
              @media screen and (max-width: 1130px) {
                min-width: 270px;
              }
            }
          }

          .total-number-time {
            text-align: center;
            background: linear-gradient(
              90deg,
              rgba(240, 206, 159, 1) 0%,
              rgba(237, 117, 28, 1) 21%,
              rgba(237, 117, 28, 1) 100%
            );
            min-width: 450px;
            width: auto;
            height: 70px;
            line-height: 60px;
            color: white;
            position: relative;
            margin-top: 20px;
            @media screen and (max-width: 1130px) {
              min-width: 350px;
            }

            .totalNumberTime__innerWrapper {
              display: flex;
              justify-content: space-between;
              padding-left: 25px;
              padding-right: 25px;
              align-items: center;
              height: 100%;
              @media screen and (max-width: 1130px) {
                padding-left: 0px;
                padding-right: 15px;
              }

              @media screen and (max-width: 965px) {
                padding-left: 15px;
              }

              .legend {
                display: flex;
                align-items: center;

                p {
                  padding-left: 5px;
                  font-family: "Noto Sans";
                  font-size: 18px;
                  font-weight: 700;
                  font-style: normal;
                  color: #ffffff;
                  margin: 0;
                }
              }

              .data {
                font-family: Roboto;
                font-size: 44px;
                font-weight: 700;
                font-style: normal;
                color: #ffffff;
                margin: 0;
                margin-left: 0.75rem;
                @media screen and (max-width: 1130px) {
                  font-size: 32px;
                }
              }
            }

            &:before {
              content: "";
              width: 0px;
              height: 0px;
              border-top: 70px solid #f0cc9c;
              border-left: 70px solid transparent;
              position: absolute;
              right: 100%;
              top: 0px;

              @media screen and (max-width: 965px) {
                border: none;
              }
            }

            &.total-number-time-sub {
              min-width: 350px;
              width: auto;
              background: linear-gradient(
                90deg,
                rgba(255, 209, 155, 1) 0%,
                rgba(255, 184, 130, 1) 100%,
                rgba(255, 252, 250, 1) 100%
              );

              @media screen and (max-width: 1130px) {
                min-width: 270px;
              }

              &:before {
                border-top: 70px solid #ffd09a;
              }
            }
          
          }
        
        
        }

        .cycleTimeChartWrapper {
          min-width: 452px;
          min-height: 109px;
          margin-top: 31px;
          padding-right: 48px;
          @media screen and (max-width: 965px) {
            margin-top: 15px;
            padding-right: 0px;
          }
        }
      }
    
    }
  }
  
  .work-footer {
    height: 160px;
    background: #7b7b7b;
    background: linear-gradient(180deg, #7b7b7b 0%, #3e3e3e 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    @media screen and (max-width: 965px) {
      height: 140px;
    }

    @media screen and (max-width: 560px) {
      height: 100px;
    }

    .workFooter__innerWrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      .level {
        font-family: "Noto Sans";
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        color: #ffffff;
        margin-right: 20px;

        span {
          font-size: 18px;
        }
      }

      .levelProgressWrapper {
        @media screen and (min-width: 1281px) {
          width: 50%;
        }
        .progressBar {
          @media screen and (max-width: 1281px) {
            width: 600px;
          }
          // @media screen and (max-width: 1130px) {
          //   width: 500px;
          // }
          @media screen and (max-width: 965px) {
            width: 400px;
          }
          @media screen and (max-width: 560px) {
            width: 280px;
          }
        }
      }
    }
    .ant-progress-bg {
      height: 35px !important;
      @media screen and (max-width: 560px) {
        height: 30px !important;
      }
    }
    .ant-progress .ant-progress-inner {
      background-color: #e2e2e2;
    }
    .ant-progress-outer {
      // min-width: 900px;
      @media screen and (max-width: 1200px) {
        min-width: 400px;
      }
      @media screen and (max-width: 560px) {
        min-width: 250px;
      }
    }
  }
}
.ant-progress .ant-progress-steps-outer {
  gap: 3px !important;
}
