.cycle-time-settings-container {
  font-family: "Noto Sans";
  font-style: normal;
  color: #7b7b7b;
  .title-text {
    font-size: 14px;
    font-weight: bold;
    color: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;
  }
  .row-text {
    min-height: 42px;
    font-size: 14px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #7b7b7b;
    padding: 11px 10px 9px 10px;
  }

  .btn-return {
    border-radius: 10px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #aca5a5;
    background: linear-gradient(
      180deg,
      #aca5a5 0%,
      #7b7b7b 91.63%,
      #5d5858 100%
    );
    font-family: "Noto Sans";
    font-size: 16px;
    font-weight: bold;
    font-style: normal;
    text-align: left;
    color: #ffffff;
    min-width: 280px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .btn-keep {
    background: #35d6fc;
    background: linear-gradient(
      180deg,
      #35d6fc 0%,
      #27b6d8 91.63%,
      #1d93af 100%
    );
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: none;
    color: #fff;
  }
  .btn-return:focus-within {
    border: none;
    color: #fff;
  }
  .btn-return:hover {
    border: none;
    color: #fff;
  }
  .ant-btn:hover {
    color: #fff;
    border: none;
  }
  .ant-btn-default {
    color: #fff;
    border: none;
  }
}
