.setting-form {
  .title-device {
    letter-spacing: 0px;
    color: #7b7b7b;
    opacity: 1;
    font-family: "Noto Sans";
    font-weight: bold;
    font-size: 14px;
    display: flex;
    justify-content: center;
  }
  .select-device {
    background: #ffffff 0% 0% no-repeat padding-box;
    // border: 1px solid #7b7b7b;
    border-radius: 10px;
    opacity: 1;
    // height: 60px;
    min-width: 590px;
  }
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 60px;
    padding: 10px 11px;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border: 1px solid #7b7b7b;
    border-radius: 10px;
  }

  .ant-select-selector:focus-within {
    border: 1px solid #7b7b7b !important;
  }

  .ant-select-selector:hover {
    border: 1px solid #7b7b7b !important;
  }

  .ant-select-selector {
    display: flex;
    align-items: center;
  }

  .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
    font-family: "Noto Sans";
    font-size: 14px;
    font-weight: bold;
    line-height: 45.5px;
    font-style: normal;
    text-align: left;
    color: #7b7b7b;
  }
  .button-decide {
    color: #fff;
    border-radius: 10px;
    width: 280px !important;
    height: 60px;
    filter: drop-shadow(0px 3px 3px rgba(0, 0, 0, 0.16));
    background: #35d6fc;
    background: linear-gradient(
      180deg,
      #35d6fc 0%,
      #27b6d8 91.63%,
      #1d93af 100%
    );
  }
  .bt-return {
    background: linear-gradient(
      180deg,
      #aca5a5 0%,
      #7b7b7b 91.63%,
      #5d5858 100%
    );
  }
  .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    border: none;
    color: #fff;
  }
  .button-decide:focus-within {
    border: none;
    color: #fff;
  }
  .button-decide:hover {
    border: none;
    color: #fff;
  }
  .ant-btn:hover {
    color: #fff;
    border: none;
  }
  .ant-btn-default {
    color: #fff;
    border: none;
  }
}
