@media only screen and (max-width: 1281px) {
  .header-admin {
    width: 1280px;
  }
}
.ant-input {
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #7b7b7b !important;
  border-radius: 10px !important;
  opacity: 1 !important;
  max-width: 590px;
  max-height: 60px;
  font-family: "Noto Sans";
}

.returnBtn {
  cursor: pointer;
  margin-right: 30px;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 2px;
  padding-bottom: 2px;
  max-height: 30px;
  border-radius: 7px;
  border: 2px solid #7b7b7b;
  font-family: "Noto Sans";
  font-weight: 700;
  font-style: normal;
  font-size: 16px;
  color: #7b7b7b;

  @media screen and (min-width: 1280px) {
    margin-right: 80px;
  }
}